<template>
  <v-row>
    <v-col cols="12" sm="6">
      <search-box
          :hidden-clear="false"
          label="Cliente"
          popup-label="Selecione um Cliente"
          :id.sync="value.idCliente"
          :nome.sync="value.nome_idCliente"
          :value="cliente"
          :loader-fn="loaderClientes"
          item-key="id"
          item-text="nome">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Cliente encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" sm="3">
      <lookup-dominio label="Tipo Fatura"
                      hint="Selecione qual o tipo da Fatura"
                      v-model="value.tipoFatura"
                      :type="lookups.tipoFatura"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" sm="3">
      <lookup-dominio label="Status Pagamento"
                      v-model="value.status"
                      :type="lookups.status"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" sm="4" >
      <v-radio-group v-model="value.tipoPesquisaData" 
          row 
          :rules="[
                this.$validators.string.required,
          ]">
        <v-radio
          label="Período"
          value="PE"
        ></v-radio>
        <v-radio
          label="Mês Referência"
          value="MR"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="4" v-show="value.tipoPesquisaData === 'MR'">
      <v-text-field label="* Mês referência"
            dense
            type="month"
            :max="dataMaxima()"
            :min="dataMinima()"                    
            v-model="value.mesRef"
            :value="this.mesAnoAtual"
            :rules="[
                this.$validators.string.required,
          ]"/>
    </v-col> 
    <v-col cols="12" sm="4" v-show="value.tipoPesquisaData === 'PE'">
      <lookup-dominio label="Filtrar por"
                      v-model="value.filtrarPor"
                      :type="lookups.filtrarPor"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" sm="4" >
      <lookup-dominio label="Forma pagamento"
                      v-model="value.formaPagamento"
                      :type="lookups.formaPagamento"
                      :hidden-clear="false"/>
    </v-col>       
    <v-col cols="12" sm="6" v-show="value.tipoPesquisaData === 'PE'">
      <v-text-field label="Data Inicio"
                    dense
                    type="date"
                    v-model="value.dtInicio"
                    :value="this.inicioMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
    <v-col cols="12" sm="6" v-show="value.tipoPesquisaData === 'PE'">
      <v-text-field label="Data Fim"
                    dense
                    type="date"
                    v-model="value.dtFim"
                    :value="this.fimMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
    <v-col cols="12" sm="3">
      <v-row>
        <v-col cols="12">
          <strong>Filtros de Matrícula</strong>
          <v-divider/>
        </v-col>
        <v-col cols="12" sm="12">
          <search-box
              :hidden-clear="false"
              label="Local"
              popup-label="Selecione um local"
              :id.sync="value.idLocal"
              :descricao.sync="value.descricao_idLocal"
              :value="local"
              :loader-fn="loaderLocal"
              item-key="id"
              item-text="descricao">
            <template v-slot:empty>
              <p class="ma-2">
                Nenhum local encontrado
              </p>
            </template>
          </search-box>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="9">
      <v-row>    
        <v-col cols="12">
          <strong>Filtros Competição</strong>
          <v-divider/>
        </v-col>
        <v-col cols="12" sm="4">
          <search-box
              label="Evento"
              popup-label="Selecione um Evento"
              :id.sync="value.idCompEvento"
              :descricao.sync="value.descCompEvento"
              :value="evento"
              :loader-fn="loaderEventos"
              item-key="id"
              item-text="descricao">
            <template v-slot:empty>
              <p class="ma-2">
                Nenhum Evento encontrado
              </p>
            </template>
          </search-box>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field dense label="Ano Competência" 
                v-model="value.anoCompetencia"
                :rules="[
                    v => this.$validators.number.greaterThanOrEquals(v, 2000),
                    v => this.$validators.number.lessThanOrEquals(v, this.anoPermitidoFim())
                ]" type="number"/>
        </v-col>  
        <v-col cols="12" sm="4">
          <search-box
              label="Etapa"
              popup-label="Selecione uma Etapa"
              :id.sync="value.idCompEtapa"
              :descricao.sync="value.descCompEtapa"
              :value="etapa"
              :loader-fn="loaderEtapas"
              item-key="id"
              item-text="descricao">
            <template v-slot:empty>
              <p class="ma-2">
                Nenhuma Etapa encontrada
              </p>
            </template>
          </search-box>
        </v-col>
      </v-row>
    </v-col>        
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllClientes} from "../../../../api/clientes";
import {findAll as findAllLocal} from "../../../../api/locais";
import {findAll as findAllEventos} from "../../../../api/eventos";
import {findAll as findAllEtapas} from "../../../../api/etapas";

import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioControleRecebimentoForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object
    },
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STS_PAGAMENTO,
        filtrarPor: ItemDeDominio.ARR_DATA_FILTRO_RECB,
        formaPagamento: ItemDeDominio.ARR_FORMA_PGTO,
        tipoFatura: ItemDeDominio.ARR_TIPO_FATURA,
      },
      loaderClientes(page, search) {
        return findAllClientes(page, {
          nome: search
        })
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
      loaderEventos(page, search) {
        return findAllEventos(page, {
          descricao: search
        })
      },
      loaderEtapas(page, search) {
        return findAllEtapas(page, {
          descricao: search
        })
      }, 
    }
  },
  computed: {
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nome_idCliente
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
    evento() {
      if(this.value.idCompEvento == null) return null;
      return {
        id: this.value.idCompEvento,
        descricao: this.value.descCompEvento
      }
    },
    etapa() {
      if(this.value.idCompEtapa == null) return null;
      return {
        id: this.value.idCompEtapa,
        descricao: this.value.descCompEtapa
      }
    },
    inicioMes: function () {
      if (typeof this.value.dtInicio === "undefined"){
        this.value.dtInicio = moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    fimMes: function () {
      if (typeof this.value.dtFim === "undefined"){
        this.value.dtFim = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
    anoAtual: function () {
      if (typeof this.value.competicao_ano === "undefined"){
        this.value.competicao_ano = moment().format('YYYY');
      }
    },
    mesAnoAtual: function () {
      if (typeof this.value.mesRef === "undefined"){
        this.value.mesRef = moment().format('YYYY-MM');
      }
    },
  },
  mounted() {
    //this.value.tipoPesquisaData = "PE";
  },
  methods: {
    anoPermitidoFim() {
        return parseInt(moment().format('YYYY'))+2;
    },  
    dataMaxima() {
      return (parseInt(moment().format('YYYY')) + 1) +"-12";
    },
    dataMinima() {
      return (parseInt(moment().format('YYYY')) - 1) +"-01";
    },   
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>