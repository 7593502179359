var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('search-box',{attrs:{"hidden-clear":false,"label":"Cliente","popup-label":"Selecione um Cliente","id":_vm.value.idCliente,"nome":_vm.value.nome_idCliente,"value":_vm.cliente,"loader-fn":_vm.loaderClientes,"item-key":"id","item-text":"nome"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCliente", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nome_idCliente", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Cliente encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"Tipo Fatura","hint":"Selecione qual o tipo da Fatura","type":_vm.lookups.tipoFatura,"hidden-clear":false},model:{value:(_vm.value.tipoFatura),callback:function ($$v) {_vm.$set(_vm.value, "tipoFatura", $$v)},expression:"value.tipoFatura"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"Status Pagamento","type":_vm.lookups.status,"hidden-clear":false},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
              this.$validators.string.required ]},model:{value:(_vm.value.tipoPesquisaData),callback:function ($$v) {_vm.$set(_vm.value, "tipoPesquisaData", $$v)},expression:"value.tipoPesquisaData"}},[_c('v-radio',{attrs:{"label":"Período","value":"PE"}}),_c('v-radio',{attrs:{"label":"Mês Referência","value":"MR"}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.tipoPesquisaData === 'MR'),expression:"value.tipoPesquisaData === 'MR'"}],attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Mês referência","dense":"","type":"month","max":_vm.dataMaxima(),"min":_vm.dataMinima(),"value":this.mesAnoAtual,"rules":[
              this.$validators.string.required ]},model:{value:(_vm.value.mesRef),callback:function ($$v) {_vm.$set(_vm.value, "mesRef", $$v)},expression:"value.mesRef"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.tipoPesquisaData === 'PE'),expression:"value.tipoPesquisaData === 'PE'"}],attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"Filtrar por","type":_vm.lookups.filtrarPor,"hidden-clear":false},model:{value:(_vm.value.filtrarPor),callback:function ($$v) {_vm.$set(_vm.value, "filtrarPor", $$v)},expression:"value.filtrarPor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"Forma pagamento","type":_vm.lookups.formaPagamento,"hidden-clear":false},model:{value:(_vm.value.formaPagamento),callback:function ($$v) {_vm.$set(_vm.value, "formaPagamento", $$v)},expression:"value.formaPagamento"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.tipoPesquisaData === 'PE'),expression:"value.tipoPesquisaData === 'PE'"}],attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Data Inicio","dense":"","type":"date","value":this.inicioMes,"rules":[
                      this.$validators.string.required ]},model:{value:(_vm.value.dtInicio),callback:function ($$v) {_vm.$set(_vm.value, "dtInicio", $$v)},expression:"value.dtInicio"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.tipoPesquisaData === 'PE'),expression:"value.tipoPesquisaData === 'PE'"}],attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Data Fim","dense":"","type":"date","value":this.fimMes,"rules":[
                      this.$validators.string.required ]},model:{value:(_vm.value.dtFim),callback:function ($$v) {_vm.$set(_vm.value, "dtFim", $$v)},expression:"value.dtFim"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Filtros de Matrícula")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('search-box',{attrs:{"hidden-clear":false,"label":"Local","popup-label":"Selecione um local","id":_vm.value.idLocal,"descricao":_vm.value.descricao_idLocal,"value":_vm.local,"loader-fn":_vm.loaderLocal,"item-key":"id","item-text":"descricao"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idLocal", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricao_idLocal", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum local encontrado ")])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Filtros Competição")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"Evento","popup-label":"Selecione um Evento","id":_vm.value.idCompEvento,"descricao":_vm.value.descCompEvento,"value":_vm.evento,"loader-fn":_vm.loaderEventos,"item-key":"id","item-text":"descricao"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEvento", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Evento encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"Ano Competência","rules":[
                  function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 2000); },
                  function (v) { return this$1.$validators.number.lessThanOrEquals(v, this$1.anoPermitidoFim()); }
              ],"type":"number"},model:{value:(_vm.value.anoCompetencia),callback:function ($$v) {_vm.$set(_vm.value, "anoCompetencia", $$v)},expression:"value.anoCompetencia"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"Etapa","popup-label":"Selecione uma Etapa","id":_vm.value.idCompEtapa,"descricao":_vm.value.descCompEtapa,"value":_vm.etapa,"loader-fn":_vm.loaderEtapas,"item-key":"id","item-text":"descricao"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEtapa", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEtapa", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Etapa encontrada ")])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }